.small-form {
  max-width: 560px;
  margin: 0 auto;
}

.form-group {
  margin-top: $base-spacing;
  position: relative;
  transform: translateZ(0);
  line-height: 1;

  input:not([type='checkbox']):not([type='radio']):not([type='file']) {
    border-radius: $border-radius;
    border: none;
    background: transparent;
    padding: #{$base-spacing + 8px} $base-spacing #{$base-spacing - 8px};
    background-color: $color-gray-5;
    color: $color-gray-1;
    display: block;
    transition: all 0.1s ease;
    width: 100%;
    // background-image: url('/assets/icons/form-error.svg');
    // background-repeat: no-repeat;
    // background-position: right -24px center;
    transform: translateZ(0);
    height: 50px;

    .dark-form & {
      border: none;
      background-color: $color-gray-2;
      color: $color-light;
    }

    + label {
      position: absolute;
      left: $base-spacing;
      top: $base-spacing + 3px;
      transform-origin: left top;
      transition: transform 0.1s ease, color 0.1s ease, opacity 0.1s ease;
      pointer-events: none;
      color: $color-gray-1;
      transform: translateZ(0);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 26px);

      .dark-form & {
        opacity: 0.5;
        color: $color-light;
      }
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 100px $color-gray-5 inset;
      -webkit-text-fill-color: $color-gray-1;

      .dark-form & {
        box-shadow: 0 0 0 100px $color-gray-2 inset;
        -webkit-text-fill-color: $color-light;
      }
    }

    &:focus {
      background-color: $color-gray-5;

      .dark-form & {
        background-color: $color-gray-3;
      }

      &:-webkit-autofill {
        box-shadow: 0 0 0 100px $color-gray-5 inset;

        .dark-form & {
          box-shadow: 0 0 0 100px $color-gray-3 inset;
        }
      }
    }

    &:focus,
    &.not-empty {
      + label {
        transform: translateZ(0) translateY(#{- ($base-spacing/2) + 2px})
          scale(0.7);
      }
    }

    &.ng-invalid {
      .submitted & {
        color: $color-primary;
        // padding-right: $base-spacing + 24px;
        // background-position: right #{$base-spacing/2} center;

        + label {
          color: $color-primary;
          opacity: 1;
          font-weight: bold;
        }
      }
    }
  }
}

.align-button-and-input {
  display: flex;

  div.form-group {
    flex: 1 1 auto;
    position: relative;
    z-index: 2;

    input:not([type='checkbox']):not([type='radio']):not([type='file']) {
      border-radius: $border-radius 0 0 $border-radius;
      height: 100%;
    }
  }

  button {
    flex: 0 0 auto;
    width: auto;
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.form-row {
  display: flex;

  .form-group {
    flex: 1 1 auto;

    &:first-child {
      margin-right: $base-spacing/2;
    }

    &:last-child {
      margin-left: $base-spacing/2;
    }
  }
}

.form-row-3-1 {
  .form-group {
    &:first-child {
      width: 75%;
    }

    &:last-child {
      width: 25%;
    }
  }
}

.form-row-1-3 {
  .form-group {
    &:first-child {
      width: 25%;
    }

    &:last-child {
      width: 75%;
    }
  }
}

.form-row-3-1 {
  .form-group {
    &:first-child {
      width: 75%;
    }

    &:last-child {
      width: 25%;
    }
  }
}
