/* You can add global styles to this file, and also import other style files */
@import 'src/assets/styles/variables';
@import 'src/assets/styles/reset';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/buttons';
@import 'src/assets/styles/forms';
@import 'src/assets/styles/links';
@import 'src/assets/styles/pages';
@import 'angular-notifier/styles';
@import '@ng-select/ng-select/themes/default.theme.css';

.popup-error,
.notifier__notification--error {
  top: 120px !important;
  border: 1px solid #fff0f4 !important;
  background-color: #fff4f7 !important;
  box-sizing: border-box !important;
  box-shadow: inset 3px 0px 0px #ff2963 !important;
  border-radius: 10px !important;
}

.popup-success,
.notifier__notification--success {
  top: 120px !important;
  border: 1px solid #e4f8e5 !important;
  background-color: #f2fcf3 !important;
  box-sizing: border-box !important;
  box-shadow: inset 3px 0px 0px #02c20f !important;
  border-radius: 10px !important;
}

.notifier__notification-message {
  top: 120px !important;
  color: #242632 !important;
  opacity: 0.7 !important;
}

.notifier__notification-button-icon {
  fill: #242632 !important;
  margin-bottom: 20px;
}

html {
  // height: 100%;
  width: 100%;
  // overflow: hidden;
}

body {
  font-family: 'Gilroy';
  color: $color-light;
  font-size: 16px;
  // height: 100%;
  padding: 0;
  // overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
    font-family: 'Gilroy';
    -webkit-tap-highlight-color: transparent;
    white-space: pre-wrap;
  }
}

.router-container {
  // position: fixed;
  // height: 100vh;
  // width: 100vw;
  // overflow-x: hidden;
  // overflow-y: scroll;
  // top: 0;
  // left: 0;

  &.noscroll {
    overflow-y: hidden;
  }
}

b,
strong {
  font-weight: bold;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

.form-and-loader-container {
  position: relative;
}

.loading-container {
  position: absolute;
  top: 100px;
  width: 100%;
  display: block;
  // transform: translateY(-50%);
}

// Tibor wants the scrollbars back
// ::-webkit-scrollbar {
// width: 0px;
/* Remove scrollbar space */
// background: transparent;
/* Optional: just make scrollbar invisible */
// }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.ng-select.custom .ng-select-container {
  height: 50px;

  margin-top: 5px;
  width: 100%;
  padding: 15px 15px;
  background: #fafafa;
  border: 1px solid rgba(36, 38, 50, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #242632;
}

.ng-select.custom input {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  font-family: Gilroy !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #242632 !important;
}

.ng-select.custom .ng-value-container {
  padding-left: 0px !important;
}

.ng-dropdown-panel-items .ng-option .ng-option-label {
  text-transform: none;
}

.ng-select.custom .ng-value.ng-star-inserted {
  text-transform: none;
}

.ng-select.custom .ng-clear-wrapper.ng-star-inserted {
  padding-top: 3px;
}

.ng-select.custom .ng-option.ng-option-disabled.ng-star-inserted {
  text-transform: none;
}

.qr-code {
  width: 191px;
  height: 191px;
  margin: 33px auto 35px auto;
  padding: 30px;
  border: 1px solid $color-gray-light;
  border-radius: 24px;
}
