$color-primary: #ff1a56; // color detailed in styleguide
$color-pink: #ff2963;
$color-gray-dark-2: #1a1c22;
$color-gray-dark-1: #1f242d;
$color-gray-dark: #1a1e26;
$color-gray-1: #1f232e; // color detailed in styleguide
$color-gray-2: #2e333f;
$color-gray-3: #464e5b;
$color-gray-4: #8390a0;
$color-gray-4-light: #9cabbd;
$color-gray-5: #eeeeee;
$color-dark: #242632;
$color-light: #ffffff;
$color-dark: #242632;
$color-box-shadow: #3d3d3d24;
$color-green: #02c20f;

$color-black: #000000;
$color-gray-dark: #1f232e;
$color-gray-medium: #8290a1;
$color-gray-light: #d2d9e1;
$gradient-red: linear-gradient(218.1deg, #ff3f71 0%, #fe0048 110.93%);
$box-shadow: 0px 8px 16px rgba(255, 26, 86, 0.2),
  0px 8px 48px rgba(0, 0, 0, 0.5);

$gradient: linear-gradient(225deg, #ff3f71 0%, #fe0048 100%);
$gradient-2: linear-gradient(246.15deg, #ff3e70 15.67%, #ff0049 83.93%);
$gradient-3: linear-gradient(222.01deg, #ff3f6c -3.19%, #fe0048 98.76%);

$red-gradient-light-horizontal: linear-gradient(
  90deg,
  rgba(255, 255, 255, 0) 0%,
  #ff1a56 50%,
  rgba(255, 255, 255, 0) 100%
);
$black-gradient-light-horizontal: linear-gradient(
  90deg,
  rgba(255, 255, 255, 0) 0%,
  #ffffff 50%,
  rgba(255, 255, 255, 0) 100%
);
$black-gradient-light-vertical: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 0%,
  #ffffff 50%,
  rgba(255, 255, 255, 0) 100%
);

$gray-gradient: linear-gradient(
  180deg,
  $color-gray-dark 0px,
  $color-gray-1 200px
);
$gray-gradient-1: linear-gradient(
  180deg,
  $color-gray-dark-1 0px,
  $color-gray-1 200px
);
$gray-gradient-light: linear-gradient(
  180deg,
  $color-gray-2 0px,
  $color-gray-1 200px
);
$shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
$gray-gradient-2: linear-gradient(25deg, $color-gray-1 50%, $color-gray-2 100%);
$gray-gradient-3: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 100%,
  rgba(255, 62, 112, 0.1) 10%
);

$border-radius: 10px;
$base-spacing: 16px;
