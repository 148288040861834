button,
.button {
  background-color: transparent;
  border: none;
}

.button {
  border-radius: $border-radius;
  padding: calc($base-spacing / 2) $base-spacing;
  color: $color-light;
  text-decoration: none;
  display: block;
  width: 100%;
  font-weight: bold;
  text-align: center;
  margin: $base-spacing 0;
  position: relative;
  overflow: hidden;
  box-shadow: $shadow;
  background: $gradient;
  line-height: 1;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    background-color: $color-light;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(100%, -50%) translateZ(0);
    transition: all 0.2s ease-out;
    opacity: 0;
  }

  &.inline {
    display: inline-block;
    width: auto;
    &.extra-spacing {
      margin: $base-spacing;
    }
  }

  &.bottomless {
    margin-bottom: 0;
  }

  &.headless {
    margin-top: 0;
  }

  &.auto-width {
    width: auto;
    display: inline-block;
  }

  &.big {
    padding: $base-spacing $base-spacing * 1.5;
  }

  &.extra-spacing {
    padding-left: $base-spacing * 2;
    padding-right: $base-spacing * 2;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.animate-top {
    &:after {
      transform: translate(-50%, -150%) translateZ(0);
    }
  }

  &:hover,
  &:active {
    &:after {
      transform: translate(-50%, -50%) translateZ(0);
      opacity: 0.3;
      border-radius: 0;
    }
  }

  &.white {
    background: $color-light;
    color: $color-primary;

    &:after {
      background-color: $color-primary;
    }
  }

  &.gray {
    background: $gray-gradient-2;
  }

  &.outline {
    background: transparent;
    color: $color-primary;
    box-shadow: inset 0px 0px 0px 1px $color-primary;
    transition: color 0.2s ease;

    &:after {
      background-color: $color-primary;
    }

    &.white {
      box-shadow: inset 0px 0px 0px 1px $color-light;
      color: $color-light;

      &:after {
        background-color: $color-light;
      }
    }
  }
}

.button-container {
  display: flex;
  margin: 0 #{-$base-spacing/2};

  &:hover {
    text-decoration: none;
  }

  .button {
    flex: 1 1 auto;
    margin: 0 calc($base-spacing / 2);
  }
}

.sign-in-button,
.log-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 145px;
  width: fit-content;
  height: 56px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: $color-light !important;
  border-radius: 12px;
  transition: 0.3s;
  cursor: pointer;
}

.sign-in-button:hover,
.log-in-button:hover {
  text-decoration: none;
}

.sign-in-button:disabled {
  opacity: 0.5;
}

.sign-in-button {
  background: $gradient-3;

  &.tariff-button {
    width: 280px;
    color: $color-gray-dark !important;
    background: $color-light;
  }

  &:hover {
    box-shadow: 0px 8px 16px rgba(255, 26, 86, 0.2);
  }

  a {
    color: $color-light !important;

    &:hover {
      text-decoration: none;
    }
  }
}

.sign-in-button.width-100 {
  width: 100%;
}

.log-in-button {
  background: $color-gray-medium;

  &:hover {
    background-color: rgba(130, 144, 161, 0.6);
  }
}

@media only screen and (min-width: 700px) {
  .sign-in-button,
  .log-in-button {
    height: 48px;
  }
}

.link-item {
  font-size: 18px;
  line-height: 21px;

  span {
    font-weight: 500;
    color: $color-gray-medium;
  }

  a {
    font-weight: 700;
    color: $color-primary;

    img {
      margin: 3px 0 4px 6px;
    }
  }
}

a.return {
  font-weight: 500;
  font-size: 18px;
  color: $color-gray-medium !important;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  img {
    height: 14px;
    margin-bottom: 2px;
    margin-right: 4px;
  }
}

.transparent-button {
  font-weight: 700;
  font-size: 16px;
  color: $color-gray-dark;
  padding: 10px 20px 9px;
  border: 1px solid rgba(130, 144, 161, 0.2);
  border-radius: 12px;
  transform: 0.5s;

  &:hover {
    background-color: $color-gray-light;
  }
}
